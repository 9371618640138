import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ResetStyles } from './Components/Styles/ResetStyles';
import NotFound from './Pages/NotFound/NotFound';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Login from './Pages/Login/Login';
import Informacoes from './Pages/Informacoes/Informacoes';
import Protocolo from './Pages/Protocolo/Protocolo';
import Resumo from './Pages/Resumo/Resumo';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

export default function App() {
  return (
    <>
      <ResetStyles />
      <Navbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="/informacoes"
          element={
            <ProtectedRoute>
              <Informacoes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/protocolo"
          element={
            <ProtectedRoute>
              <Protocolo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resumo"
          element={
            <ProtectedRoute>
              <Resumo />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </>
  );
}
