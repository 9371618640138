// protectedroute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    //console.log("ProtectedRoute - isAuthenticated:", isAuthenticated);

    if (isAuthenticated === null) {
        return <div></div>;
    }

    return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
