import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: #fedb00;
    z-index: 1000;

    p {
        color: #777777;
    }
`;