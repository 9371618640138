// authContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [currentStep, setCurrentStep] = useState(null);
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const checkAuth = async () => {
            try {
                //console.log("Checking authentication...");

                // Verify token with backend
                await axios.get(`${API_BASE_URL}/api/verify-token`, { withCredentials: true });
                
                const encryptedId = localStorage.getItem('userId');
                const encryptedPharmacy = localStorage.getItem('userPharmacyId');

                if (encryptedId && encryptedPharmacy) {
                    const decryptedId = CryptoJS.AES.decrypt(encryptedId, SECRET_KEY).toString(CryptoJS.enc.Utf8);
                    const decryptedPharmacy = CryptoJS.AES.decrypt(encryptedPharmacy, SECRET_KEY).toString(CryptoJS.enc.Utf8);

                    if (decryptedId && decryptedPharmacy) {
                        setUser({ id: decryptedId, profile: decryptedPharmacy });
                        setIsAuthenticated(true);
                        //console.log("User authenticated:", { id: decryptedId, profile: decryptedPharmacy });
                    } else {
                        setIsAuthenticated(false);
                        setUser(null);
                        //console.log("Failed to decrypt user data.");
                    }
                } else {
                    setIsAuthenticated(false);
                    setUser(null);
                    //console.log("No encrypted data found in localStorage.");
                }
            } catch (error) {
                setIsAuthenticated(false);
                setUser(null);
                //console.log("Error verifying token:", error);
            }
        };
        checkAuth();
    }, [SECRET_KEY]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, setUser, setIsAuthenticated, currentStep, setCurrentStep }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
