import styled from "styled-components";

export const ResumoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;

    .boxes-div {
        display: flex;
        grid-gap: 25px;
        flex-wrap: wrap;

        .box-white {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            padding: 25px;
            width: 390px;

            .box-white-top {
                display: flex;
                justify-content: space-between;
                padding-bottom: 12px;
                margin-bottom: 12px;
                border-bottom: 1px solid #605e5c;

                .icon-div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .box-white-bottom {
                display: flex;
                flex-direction: column;
                grid-gap: 8px;
                border: 1px solid #e1dfdd;
                border-radius: 4px;
                padding: 12px;
                margin-bottom: 12px;


                div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .box-gray {
            display: flex;
            flex-direction: column;
            grid-gap: 25px;
            padding: 25px;
            width: 390px;
            background-color: #f3f2f1;
        }
    }

    .nome-atendente-div {
        div {
            display: flex;
            grid-gap: 25px;
        }
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .form-control {
        display: block;
        height: 34px;
        width: 282px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: none;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1100;

        .modal-content {
            background: #fff;
            padding: 20px;
            border-radius: 4px;
            max-width: 500px;
            width: 100%;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .modal-title {
                font-size: 18px;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 10px 0;
        }

        .modal-footer {
            display: flex;
            justify-content: flex-end;

            .ok_btn {
                padding: 6px 12px;
                font-size: 14px;
                background-color: #281E78;
                color: #ffffff;
                border-radius: 4px;
                cursor: pointer;
                border: none;
            }

            .ok_btn:hover {
                background-color: #1a144f;
            }
        }
    }

    .device-items div {
        width: 100%;
        border: 1px solid #e1dfdd;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 16px;
    }
`;
