import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 85px;
    
    .panel {
        max-width: 530px;
        border: 1px solid #ddd;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        h2 {
            padding: 32px 96px;
            font-size: 30px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
        }

        .panel-heading {
            background-color: #f5f5f5;
            border-bottom: 1px solid #ddd;
        }

        .panel-body {
            padding: 32px 96px;
            display: flex;
            flex-direction: column;
            grid-gap: 15px;
            background-color: #ffffff;

            .form-control {
                display: block;
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.428571429;
                color: #555555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                outline: none;
            }
        }
    }

    .button-width {
        padding: 6px 12px;
        width: 80px;
        font-size: 14px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-width:hover {
        background-color: #1a144f;
    }

    .button-width.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button-secondary {
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-secondary:hover {
        background-color: #e6e6e6;
    }

    .bold {
        font-weight: 600;
    }

    .message-box {
        padding: 15px;
        background-color: #f3f2f1;

        img {
            height: 20px;
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1100;
    }

    .modal-content {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        max-width: 600px;
        width: 100%;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 0;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
`;
