import styled from "styled-components";

export const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    position: static;
    top: 0;
    height: 70px;
    width: 100%;
    background-color: #ffffff;
    z-index: 1000;

    .icone {
        cursor: pointer;
    }

    .hamburger-icon {
        display: none;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        .logo-abbott {
            display: none;
        }

        .hamburger-icon {
            display: block;
        }
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
    position: static;
    top: 70px;
    left: 0;
    z-index: 999;

    .menu-logo-abbott {
        display: block;
    }
`;
