import React from 'react'
import { FooterContainer } from './Footer.styles'

export default function Footer() {
  return (
    <>
      <FooterContainer>
        <p>Desarrollado por Foundever</p>
      </FooterContainer>
    </>
  )
}
