import styled from "styled-components";

export const ProtocoloContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;

    .page-title {
        display: flex;
        align-items: center;
        grid-gap: 5px;

        .title {
            display: flex;
            align-items: center;
            grid-gap: 5px;
        }

        img {
            cursor: pointer;
        }
    }

    .separator {
        padding-bottom: 25px;
        border-bottom: 1px solid #605e5c;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button-secondary {
        padding: 6px 12px;
        font-size: 14px;
        width: 80px;
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-secondary:hover {
        background-color: #e6e6e6;
    }

    .button-terciary {
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #cccccc;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .button-terciary:hover {
        background-color: #e6e6e6;
        color: red;
    }
    
    .x-button-div {
        display: flex;
        align-items: end;
    }

    .product-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 50px;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #c8c6c4;

        .product-div1 {
            display: flex;
            align-items: center;
            grid-gap: 50px;
            margin-left: 50px;

            .imagem-div1 {
                transition: transform 0.3s ease; /* Adicione a transição */
                width: 100px;
            }

            .imagem-div1:hover {
                transform: scale(2); /* Aumenta o tamanho da imagem */
            }
        }
    }

    .hidden-fields {
        display: flex;
        justify-content: space-between;
        grid-gap: 25px;
        flex-wrap: wrap;

        .hidden-field {
            display: flex;
            flex-direction: column;
            grid-gap: 5px;
        }
    }

    .button-div {
        display: flex;
        grid-gap: 25px;
        justify-content: end;
    }

    .form-control {
        display: block;
        height: 34px;
        width: 165px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: none;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1100;

        .modal-content {
            background: #fff;
            padding: 20px;
            border-radius: 4px;
            max-width: 500px;
            width: 100%;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .modal-title {
                font-size: 18px;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 10px 0;
            ol {
                padding-left: 20px;
                li {
                    margin-bottom: 10px;
                }
            }
        }

        .modal-footer {
            display: flex;
            justify-content: flex-end;
            .ok_btn {
                padding: 6px 12px;
                font-size: 14px;
                background-color: #281E78;
                color: #ffffff;
                border-radius: 4px;
                cursor: pointer;
                border: none;
            }
            .ok_btn:hover {
                background-color: #1a144f;
            }
        }
    }
`;
