import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LoginContainer } from './Login.styles';
import ExclamationIcon from '../../Components/Images/Login/exclamation-icon.svg';
import CryptoJS from 'crypto-js'; // Importação adicionada
import { useAuth } from '../../AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default function Login() {
  const [bandeiras, setBandeiras] = useState([]);
  const [bandeira, setBandeira] = useState('');
  const [codBandeira, setCodBandeira] = useState('');
  const [codFarmacia, setCodFarmacia] = useState('');
  const [filial, setFilial] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [enderecoFarmacia, setEnderecoFarmacia] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true); // Adicionado
  const { setUser, setIsAuthenticated } = useAuth(); // Definição adicionada
  const navigate = useNavigate();

  const { setCurrentStep } = useAuth();
  useEffect(() => {
    setCurrentStep(0);
  }, [setCurrentStep]);

  useEffect(() => {
    setIsFormValid(bandeira !== '' && filial !== '');
  }, [bandeira, filial]);

  const handleBandeiraChange = (e) => {
    const selectedBandeira = bandeiras.find(b => b.nome === e.target.value) || {};
    setBandeira(selectedBandeira.nome || '');
    setCodBandeira(selectedBandeira.cod_bandeira || '');
  };

  const handleFilialChange = (e) => {
    setFilial(e.target.value);
  };

  const handleAcessarClick = () => {
    if (isFormValid) {
      const encryptedPharmacyId = localStorage.getItem('userPharmacyId');
      const decryptedPharmacyId = encryptedPharmacyId ? CryptoJS.AES.decrypt(encryptedPharmacyId, SECRET_KEY).toString(CryptoJS.enc.Utf8) : null;

      if (filial !== decryptedPharmacyId) {
        setErrorMessage('La sucursal no está vinculada a su usuario, póngase en contacto con un supervisor si esto es incorrecto.');
      } else {
        axios.get(`${API_BASE_URL}/api/pharmacy/store/${codBandeira}/${filial}`, { withCredentials: true })
          .then(response => {
            setCodFarmacia(response.data.Farmacia.cod_farmacia);
            setEnderecoFarmacia(response.data.Farmacia.endereco);
            setIsModalVisible(true);
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              setErrorMessage('Farmacia no encontrada');
            } else {
              setErrorMessage('Error ao buscar farmacia.');
              console.error('Erro ao buscar farmacia:', error);
            }
          });
      }
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const isTheFormValid = () => {
    return email !== '' && password !== '';
  };

  const handleConfirmFarmacia = () => {
    navigate('/informacoes', { state: { codFarmacia } });
  };

  const handleLogin = () => {
    axios.post(`${API_BASE_URL}/api/auth/pharmacy`, { email, password }, { withCredentials: true })
      .then(response => {
        const { id, userPharmacyId } = response.data.user;

        const encryptedId = CryptoJS.AES.encrypt(id.toString(), SECRET_KEY).toString();
        const encryptedPharmacy = CryptoJS.AES.encrypt(userPharmacyId.toString(), SECRET_KEY).toString();

        localStorage.setItem('userId', encryptedId);
        localStorage.setItem('userPharmacyId', encryptedPharmacy);

        setUser({ id, profile: userPharmacyId });
        setIsAuthenticated(true);

        setIsLoginFormVisible(false);
        // Limpar o estado de email e password após login
        setEmail('');
        setPassword('');

        // Chamar a API após o login
        axios.get(`${API_BASE_URL}/api/pharmacy/1`, { withCredentials: true })
          .then(response => {
            setBandeiras(response.data.Bandeiras);
          })
          .catch(error => {
            setErrorMessage('Error ao buscar bandeiras.');
            console.error('Erro ao buscar bandeiras:', error);
          });
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Error ao chamar a API');
        }
        console.error("Erro ao chamar a API:", error);
      });
  };

  return (
    <>
      <LoginContainer>
        <div className='panel'>
          <div className='panel-heading'>
            <h2>Sistema de Intercambio Libre (STL)</h2>
          </div>
          {isLoginFormVisible ? (
            <div className='panel-body'>
              <div className='input-group username-group'>
                <label className='bold'>Usuario</label>
                <br />
                <input type="text" id="username" className="form-control" placeholder="Nombre de usuario" aria-describedby="basic-addon1" onChange={handleChangeEmail} value={email} />
              </div>
              <div className='input-group password-group'>
                <label className='bold'>Contraseña</label>
                <br />
                <input type="password" id="password" className="form-control" placeholder="Escribe tu contraseña" aria-describedby="basic-addon1" onChange={handleChangePassword} value={password} />
              </div>
              <button
                id='login-btn'
                className={`button-width ${!isTheFormValid() ? 'disabled' : ''}`}
                onClick={handleLogin}
                disabled={!isTheFormValid()}
              >
                Ingresar
              </button>
              {errorMessage && <div id='message-box'>{errorMessage}</div>}
            </div>
          ) : (
            <div className='panel-body'>
              <div>
                <label className='bold'>Bandera de farmacia</label>
                <br />
                <select name="" id="" className="form-control" onChange={handleBandeiraChange} value={bandeira}>
                  <option value="">Seleccione la bandera</option>
                  {bandeiras.map((bandeira) => (
                    <option key={bandeira.cod_bandeira} value={bandeira.nome}>
                      {bandeira.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className='bold'>Identificación de la sucursal</label>
                <br />
                <input
                  type="text"
                  id="filial"
                  className="form-control"
                  placeholder="Ingrese el número de su sucursal"
                  aria-describedby="basic-addon1"
                  onChange={handleFilialChange}
                  value={filial}
                />
              </div>
              <div className='message-box'>
                <img src={ExclamationIcon} alt="exclamation-icon" />
                <span>Asegúrese de que la bandera de la farmacia y la identificación de la sucursal sean correctas. De lo contrario, los intercambios realizados se registrarán para otra farmacia.</span>
              </div>
              <button
                id='login-btn'
                className={`button-width ${!isFormValid ? 'disabled' : ''}`}
                onClick={handleAcessarClick}
                disabled={!isFormValid}
              >
                Avanzar
              </button>
              {errorMessage && <div id='message-box'>{errorMessage}</div>}
            </div>
          )}
        </div>
        {isModalVisible && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirme su farmacia</h4>
              </div>
              <div className="modal-body">
                <div>
                  <strong>BANDERA</strong>
                  <p className="company">{bandeira}</p>
                </div>
                <div>
                  <strong>DIRECCION</strong>
                  <p className="store-name">{enderecoFarmacia}</p>
                </div>
                <div>
                  <strong>Nº DE SUCURSAL</strong>
                  <p className="store-id">{filial}</p>
                </div>
              </div>
              <div>
                <p>Si esta no es su farmacia, haga clic en <strong>Cambiar farmacia.</strong></p>
              </div>
              <div className="modal-footer">
                <button type="button" className="button-secondary" onClick={() => setIsModalVisible(false)}>Cambiar farmacia</button>
                <button type="button" className="button" onClick={handleConfirmFarmacia}>Esta es mi farmacia</button>
              </div>
            </div>
          </div>
        )}
      </LoginContainer>
    </>
  );
}
