import styled from "styled-components";

export const NotfoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;

    .button {
        padding: 6px 12px;
        font-size: 14px;
        width: 100px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }
`;