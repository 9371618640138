import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotfoundContainer } from './NotFound.styles';

export default function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.from || '/';

  function Voltar() {
    navigate(previousPath);
  }

  return (
    <>
      <NotfoundContainer>
        <h2>404 - Archivo no encontrado</h2>
        <div>¡Lo siento! Parece que no puedo encontrar la página que estaba buscando.</div>
        <br />
        <button className='button' onClick={Voltar}>Regresar</button>
      </NotfoundContainer>
    </>
  )
}
