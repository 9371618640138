import styled from "styled-components";

export const InformacoesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;

    .alert-div {
        margin: 25px 0;
    }

    .alert-warning2 {
        padding: 25px;
        background-color: #faebcc;
        color: #1b1a19;
        border-color: #faebcc;
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
    }

    .protocol-details {
        border: 1px solid #d19a26;
        width: fit-content;
        padding: 24px;
        border-radius: 4px;

        .warning {
            color: #d19a26;
            font-weight: 600;
        }

        div {
            display: flex;
            flex-direction: column;
        }
    }

    .form-control {
        display: block;
        height: 34px;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: none;
    }

    .buttons-div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        width: 80px;
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button-secondary {
        padding: 6px 12px;
        font-size: 14px;
        width: 80px;
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-secondary:hover {
        background-color: #e6e6e6;
    }

    .protocolo-div {
        width: 350px;
    }

`;